<template>
  <div class="flex justify-center items-center flex-col">
    <FormError :errors="getError" />
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <InputForm
        :label="$t('global_name')"
        placeholder="Pet's name"
        v-model="petData.callName"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.callName"
      />

      <div
        class="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
      >
        <label class="text-sm font-proximaMedium" for="dateOfBirth">{{
          $t("global_date_of_birth")
        }}</label>
        <date-picker
          type="date"
          format="DD/MM/YYYY"
          v-model="dateBirth"
          @change="onChange()"
          placeholder="Select date"
          class="w-full rounded-sm my-1 text-sm"
          :class="{ 'border border-red outline-red': error }"
        ></date-picker>
        <FormError :errors="errors.dateOfBirth" />
      </div>
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <InputForm
        :label="$t('pet_record_species')"
        :placeholder="$t('pet_record_enter_pet_species')"
        v-model="petData.customSpecies"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.customSpecies"
      />

      <div
        class="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
      >
        <label class="text-sm font-proximaMedium" for="origin">{{
          $t("global_country_of_origin")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="petData.CountryofOrigin"
        >
          <option value="" disabled selected>
            {{ $t("global_select_country") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <InputForm
        :label="$t('pet_record_secondary_id_type')"
        placeholder="Maximum 25 characters"
        v-model="petData.optionalIDType"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.optionalIDType"
      />
      <InputForm
        :label="$t('pet_record_secondary_id')"
        placeholder="Maximum 25 characters"
        v-model="petData.optionalID"
        classAdd="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
        :error="errors.optionalID"
      />
    </div>
    <div class="mt-4 w-11/12 md:w-3/12">
      <div class="flex items-center justify-center">
        <InputRadio
          class="mt-4"
          :title="$t('global_sex')"
          :opt1="$t('global_female')"
          :opt2="$t('global_male')"
          @input="inputSexType"
        />
      </div>
      <div class="flex items-center justify-center">
        <InputRadio
          class="mt-4"
          :title="$t('pet_record_breeding_status')"
          :opt1="$t('global_sterilized')"
          :opt2="$t('global_intact')"
          @input="inputBreedingStatus"
        />
      </div>
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <InputForm
        :label="$t('pet_record_height')"
        placeholder="0.00 cm"
        v-model="petData.height"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.height"
      />
      <InputForm
        :label="$t('pet_record_weight')"
        placeholder="0.000 kg"
        v-model="petData.weight"
        classAdd="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
        :error="errors.weight"
      />
    </div>
    <div
      class="my-8 w-12/12 md:w-6/12 lg:w-8/12 flex flex-wrap justify-center md:justify-between items-center"
    >
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />

      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
    </div>
    <NewpetDiagnoses
      :unverifiedHealthStatus="this.unverifiedHealthStatus"
      @changeDignostic="changeDignostic"
      @deleteDiag="deleteDiag"
    />
    <div
      class="mt-8 w-11/12 md:w-6/12 lg:w-4/12 text-left font-proximaMedium text-sm"
    >
      <p class="font-proximaLight">
        <span class="text-red">{{ $t("global_note") }}</span>
        {{ $t("pet_record_text") }}
      </p>
    </div>
    <div
      class="w-full md:w-4/12 mt-4 flex flex-col md:flex-row items-center md:items-start justify-between"
    >
      <InputImagePets class="mx-1 mt-4" @input="pushToDoc" />
      <InputRadio3v
        class="mt-4"
        title="Document Type"
        :opt1="$t('pet_record_official_pedegree')"
        :opt2="$t('pet_record_EU_pet_passport')"
        :opt3="$t('pet_record_vet_hospital_bill')"
        @input="inputVal"
      />
    </div>
    <div
      class="mt-0 w-full font-proximaMedium flex justify-center items-center"
    >
      <div
        class="w-11/12 md:w-6/12 lg:w-4/12 md:mt-4 flex flex-row justify-center items-center"
      >
        <BorderedButton
          class="mr-1"
          :title="$t('global_cancel')"
          borderColor="border-red"
          textCl="text-red"
          paddingX="px-8"
          width="w-full md:w-10/12"
        />
        <SubmitButton
          @click.native="submitNewPet"
          class="ml-1"
          :title="$t('account_save_button')"
          background="bg-primary"
          paddingX="px-8"
          width="w-full md:w-10/12"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from "../forms/InputSelect.vue";
import InputRadio from "../forms/InputRadio.vue";
import InputRadio3v from "../forms/InputRadio3v.vue";
import InputRadioEach from "../forms/InputRadioEach.vue";
import InputForm from "../forms/InputForm.vue";
import NewpetDiagnoses from "./NewpetDiagnoses.vue";
import InputImagePets from "../forms/InputImagePets.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import FormError from "../warning/FormError.vue";
import moment from "moment";

import { getCountries } from "../../services/countriesServices";

import { mapActions, mapGetters } from "vuex";

export default {
  props: { file: {} },
  components: {
    InputSelect,
    InputRadio,
    InputRadioEach,
    InputRadio3v,
    InputForm,
    NewpetDiagnoses,
    InputImagePets,
    DatePicker,
    SubmitButton,
    BorderedButton,
    FormError,
  },
  data() {
    return {
      petData: {
        showTitle: [],
        workingTitle: [],
        callName: "",
        species: 5, //1 dog - 2 cat - 3 ferret - 4 bird - 5 other
        CountryofOrigin: "",
        sex: true,
        dateOfBirth: null,
        optionalID: "",
        optionalIDType: "",
        customSpecies: "",
        breedingStatus: true,
        weight: "0",
        height: "0",
        petIdentificationImage: null,
        petIdentificationImageType: 1,
      },
      dateBirth: null,
      userEmailAddress: localStorage.getItem("email"),
      vetEmailAddress: "technical@pawapeau.com", //"testing@perpetual.care",
      countriesList: [],
      unverifiedHealthStatus: [],
      petGallery: [],
      error: false,
      errors: [],
    };
  },
  computed: {
    ...mapGetters({
      getError: "petRecord/getError",
    }),
  },
  async mounted() {
    const a = await getCountries();
    this.countriesList = a.data;
  },
  methods: {
    ...mapActions({ addPetRecord: "petRecord/addPetRecord" }),
    onChange() {
      this.petData.dateOfBirth = moment(String(this.dateBirth)).format(
        "DD/MM/YYYY"
      );
    },
    inputSexType(val) {
      this.petData.sex = val == 1 ? true : false; //0 for male / 1 for Female
    },
    inputVal(i) {
      // console.log(i);
      this.petData.petIdentificationImageType = i;
    },
    inputBreedingStatus(val) {
      this.petData.breedingStatus = val == 1 ? true : false;
    },

    changeDignostic(val) {
      val.split(",").map((line) => {
        if (
          val[val.length - 1] == "," &&
          this.unverifiedHealthStatus.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.unverifiedHealthStatus.push(line);
        }
      });
    },
    deleteDiag(val) {
      const diags = this.unverifiedHealthStatus.filter((c) => c !== val);
      this.unverifiedHealthStatus = diags;
    },

    pushToGallery(val) {
      this.petGallery.push(val);
    },
    pushToDoc(val) {
      this.petData.petIdentificationImage = val;
    },

    validate() {
      const errors = [];

      if (this.petData.callName.trim() === "") errors.push("Name is required");
      if (this.petData.dateOfBirth === null) {
        errors.push(this.$t("pet_record_error_1"));
        errors.push(this.$t("pet_record_error_2"));
      }
      if (this.petData.petIdentificationImage == null)
        errors.push("A pet’s identity must be added");
      return Object.keys(errors).length === 0 ? null : errors;
    },

    async submitNewPet() {
      if (this.petData.dateOfBirth === null) {
        this.errorData = true;
      }
      const errors = this.validate();
      this.errors = errors || [];
      if (errors) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      const data = {
        petData: this.petData,
        userEmailAddress: this.userEmailAddress,
        vetEmailAddress: this.vetEmailAddress,
        unverifiedHealthStatus: this.unverifiedHealthStatus,
        petProfileImg: this.file ? this.file : null,
        petGallery: this.petGallery,
      };

      this.addPetRecord(data);
    },
  },
};
</script>

<style scoped>
.filler {
  width: 180px;
  height: 0px;
}

.mx-datepicker {
  width: 100%;
}

.mx-datepicker > div > input {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
</style>
