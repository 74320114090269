<template>
  <div class="relative">
    <NewpetForms />
    <NavMenuSecondary  />
  </div>
</template>

<script>
import NewpetForms from "../components/newpetrecord/NewpetSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue"

export default {
  components: {
    NewpetForms,
    NavMenuSecondary
  },
};
</script>